import React from 'react'
import { FaLinkedin, FaInstagram, FaFacebook } from 'react-icons/fa';
import I18n from  '../i18n/i18n'
import { Link } from 'react-router-dom'


import './Toolbar.css'
import DrawerToggleButton from '../DrawerToggleButton/DrawerToggleButton'

const toolbar = props => (
  <header className="toolbar">
    <nav className="toolbar__navigation">
      <div className="toolbar__toggle-button">
          <DrawerToggleButton click={props.drawerClickHandler} />
          </div>
      <div className="toolbar__logo">
       
      </div>
      <div className="spacer" />
      <div className="toolbar_navigation-items">
  
  <div className="nav-right">
          <I18n/>
          <a
              href="https://www.facebook.com/ann.cnop.5"
              target="_blank"
            >
              <FaFacebook color={'white'} size={20} />
            </a>
            <a
              href="https://www.linkedin.com/in/ann-cnop-501506105/"
              target="_blank"
            >
              <FaLinkedin color={'white'} size={20} />
            </a>
            <a
              href="https://www.instagram.com/ann_cnop/"
              target="_blank"
            >
              <FaInstagram color={'white'} size={20} />
            </a>
            </div>
           
         
            

            {/*<li>
            <a href="https://twitter.com/TabeaDebus" target="_blank"><FaTwitter/></a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UCIwHWkZF447ssRHDaTpWJpg" target="_blank"><FaYoutube/></a>
          </li>
          <li>
            <a href="https://open.spotify.com/artist/1MbLfjWZWdE2dt3yOJnU4e?si=Pz6mmUhSTG2Nwwu8G5Xlrg" target="_blank"><FaSpotify/></a>
          </li> */}
    

      </div>
    </nav>
  </header>
)

export default toolbar