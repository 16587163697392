import React, { useState, useEffect } from 'react'
import Translator from '../i18n/Translator'
import Titlebar from '../TitleBar';
import { getPress } from '../../contentful';
import marked from 'marked';
import { Row, Col } from 'react-bootstrap';

const Press = () => {
    const [reviews, setReviews] = useState([])

    useEffect(() => {
        getPress().then(data => setReviews(data))
    },[])
        return( 
        <div className="content">
        <Titlebar title={<Translator path="Press"/>} />

            {reviews.map(review => {
                const content = marked(review.fields.content)

                return(
                    <div className="review text-box">
                        <Row>
                            <Col md={8}>
                <h4>{review.fields.title}</h4>
                <section style={{fontStyle:'italic'}} dangerouslySetInnerHTML={{__html: content}} />
                {review.fields.link && <a className="xsmall-button" href={review.fields.link}>read online</a>}
                            </Col>
                            <Col md={4}>
                                <img style={{width:'100%'}} src={review.fields.image.fields.file.url} alt=""/>
                            </Col>
                        </Row>


                    </div>
                )
            }
            )}

        </div>
        )
    }


export default Press
