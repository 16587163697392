import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { FaSpotify, FaItunes } from 'react-icons/fa'
import marked from 'marked'
import { Link } from 'react-router-dom'

const Recording = ({cd, index}) => {
    const info = marked(cd.fields.description) 
    

    let listing;
   { index % 2 === 0 ?
    listing = <Row>
    <Col md={4} style={{display:'flex', alignItems:'center', flexDirection:'column'}} >
    <img style={{maxWidth:'100%'}} src={cd.fields.image.fields.file.url} />
    <div className="recording-images">
    {/* <a id="spotify" href="props.cd.fields.spotifyUrl" target="_blank"><FaSpotify size={25} /></a>
    <a id="itunes" href="props.cd.fields.iTunesLink" target="_blank"><FaItunes  size={25}/></a> */}
    <a className="label-name" href={cd.fields.labelLink} target="_blank">{cd.fields.labelName}</a>
    </div>
    </Col>
    <Col md={8}>
   <h5>{cd.fields.title}</h5>
    { !cd.fields.artistLink && <p><em>{cd.fields.artist}</em></p>}
{cd.fields.artistLink && <a href={cd.fields.artistLink}>{cd.fields.artist}</a>  }
    <section style={{color: 'rgb(235, 210, 192)'}} dangerouslySetInnerHTML={{ __html: info }} />
    {cd.fields.hasOwnPage && <Link to={'/recordings/' + cd.fields.title} className="xsmall-button" >more</Link> }
    </Col>
    </Row>
    
    : listing =  <Row >
        <Col md={8}>
   <h5>{cd.fields.title}</h5>
    { !cd.fields.artistLink && <p><em>{cd.fields.artist}</em></p>}
{cd.fields.artistLink && <a href={cd.fields.artistLink}>{cd.fields.artist}</a>  }
    <section style={{color: 'rgb(235, 210, 192)'}} dangerouslySetInnerHTML={{ __html: info }} />
    {cd.fields.hasOwnPage && <Link to={'/recordings/' + cd.fields.title} className="xsmall-button" >more</Link> }
    </Col>
      <Col md={4} style={{display:'flex', alignItems:'center', flexDirection:'column'}} >
    <img style={{maxWidth:'100%'}} src={cd.fields.image.fields.file.url} />
    <div className="recording-images">
    {/* <a id="spotify" href="props.cd.fields.spotifyUrl" target="_blank"><FaSpotify size={25} /></a>
    <a id="itunes" href="props.cd.fields.iTunesLink" target="_blank"><FaItunes  size={25}/></a> */}
    <a className="label-name" href={cd.fields.labelLink} target="_blank">{cd.fields.labelName}</a>
    </div>
    </Col>
    </Row>
    }



    return (
        <div style={{marginBottom:'20px'}} className="text-box recording">
       {listing}
    </div>
    )
}

export default Recording