import React from 'react'
import marked from 'marked'
import '../components/calendar/calendar.css'
import Translator from './i18n/Translator'
import { Row, Col } from  'react-bootstrap'
import { FaArrowRight } from 'react-icons/fa';

const Event = (props) => {

let details;
if(props.date.fields.desription){ details = marked(props.date.fields.description)}
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    const date = new Date(props.date.fields.date)
    return <div className={props.class}>
        <Row>
    <Col md={6}>
        <h4>{date.toLocaleDateString(window.localStorage.lang, options)}</h4>
        <h5>{props.date.fields.title}</h5>

        <p>{date.getHours()}:{String(date.getMinutes()).padStart(2, '0')}</p>
        <h5>{props.date.fields.venue}</h5>
        {details ? 
        <section style={{ fontStyle:'italic'}} dangerouslySetInnerHTML={{ __html: details}}/> : null}
     </Col>
    <Col md={6}> 
    <div>
        {props.date.fields.image ? <img src={props.date.fields.image.fields.file.url} /> : null} 
    </div>

</Col>


        </Row>
   
       
       { props.date.fields.link && <a style={{marginTop:'10px'}} className="xsmall-button" href={props.date.fields.link} target="_blank" >
            <Translator path="ViewConcert"/> <FaArrowRight/>
        </a> }
    </div>
}

export default Event
