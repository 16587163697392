import React from 'react';
import { motion } from 'framer-motion';

const Modal = ({ setSelectedImg, selectedImg, title }) => {

  const handleClick = (e) => {
    if (e.target.classList.contains('backdrop')) {
      setSelectedImg(null);
    }
  }

  return (
    <div>
 <motion.div className="backdrop" onClick={handleClick}
   initial={{ opacity: 0 }}
   animate={{ opacity: 1 }}
 >
   <motion.img src={selectedImg} alt="enlarged pic" 
     initial={{ opacity:0 }}
     animate={{ opacity:1 }}
   />
     <h5>{title}</h5>

 </motion.div>
 </div>
   )
}

export default Modal;