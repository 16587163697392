import React, { useState, useEffect } from "react";
import Event from "../Event";
import Translator from "../i18n/Translator";
import { Row, Col } from "react-bootstrap";
import { getCalendarEng } from '../../contentful';
import TitleBar from '../TitleBar'


const Calendar = () => {
  
  const [events, setEvents] = useState([])
  const [archive, setArchive] = useState([])

 

  useEffect(() => {
    window.scrollTo(0, 0);
      getCalendarEng().then(data => setEvents(data))
   }, [])



    return (
      <div className="content">
      
      <TitleBar title={<Translator path="Calendar"/>} />

         
           
           <div style={{width:'75%', margin:'auto'}}>

            {events.map((item) => (
              <Event class="event" date={item} />
             

            ))}
         
         </div>
      

        
        

           
      </div>
    );
  }

export default Calendar;
