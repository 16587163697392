const client = require('contentful').createClient({
    space: 'umyp8dyr0w8x',
    accessToken: 'RnmeYuwjhJy6lmLe9S8-sT1KyKmBXAJqOt2ELEQhGqM'
  })
  var gt = new Date().toISOString();

  
  const getNews = () => client.getEntries({content_type: 'news'}).then(response => response.items)
  const getNewsFr = () => client.getEntries({content_type: 'newsFr'}).then(response => response.items)
  const getNewsNl = () => client.getEntries({content_type: 'newsNl'}).then(response => response.items)
  
  const getBiogEng = () => client.getEntries({content_type: 'biogEng'}).then(response => response.items)
  
  const getProjectFr = () => client.getEntries({content_type: 'projectFr', order: 'fields.order'}).then(response => response.items)
  const getProjectEng = () => client.getEntries({content_type: 'projectEng', order: 'fields.order'}).then(response => response.items)
  const getProjectNl = () => client.getEntries({content_type: 'projectNl', order: 'fields.order'}).then(response => response.items)
  
  const getProjects = () => client.getEntries({content_type: 'project'}).then(response => response.items)
  const getProject = (slug) => client.getEntries({content_type: 'project', 'sys.id': slug}).then(response => response.items)
  
  
  
  
  const getRecordings = () => client.getEntries({content_type: 'recording', order:'fields.order'}).then(response => response.items)
  const getSingleRecording = (slug) => client.getEntries({content_type: 'recording', 'fields.title': slug}).then(response => response.items)
 
  const getGallery = () => client.getEntries({content_type: 'galleryImage'}).then(response => response.items)
 const getVideos = () => client.getEntries({content_type: 'video', order:'fields.order' }).then(response => response.items)
 const getPress = () => client.getEntries({content_type: 'press', order: 'fields.order'}).then(response => response.items)
 const getContactPage = () => client.getEntries({content_type: 'contactPAge'}).then(response => response.items)
 
 const getPrivacy = () => client.getEntries({content_type: 'privacy'}).then(response => response.items)
 const getHomepageText = () => client.getEntries({content_type: 'homepageText'}).then(response => response.items)

 const getCalendarNl = () => client.getEntries({content_type: 'calendarNl', order: 'fields.date' ,'fields.date[gte]': gt}).then(response => response.items)
 const getCalendarFr = () => client.getEntries({content_type: 'calendarFr', order: 'fields.date' ,'fields.date[gte]': gt}).then(response => response.items)
 const getCalendarEng = () => client.getEntries({content_type: 'calendarEng', order: 'fields.date' ,'fields.date[gte]': gt}).then(response => response.items)





  const getSinglePostEng = (slug) => client.getEntries({'fields.label': slug, content_type: 'news'}).then(response => response.items)
  const getSinglePostFr = (slug) => client.getEntries({'fields.label': slug, content_type: 'newsFr'}).then(response => response.items)
  const getSinglePostNl = (slug) => client.getEntries({'fields.label': slug, content_type: 'newsNl'}).then(response => response.items)
  




  export {getContactPage, getHomepageText, getProject, getProjects, getSingleRecording, getPress, getVideos, getCalendarEng, getPrivacy, getBiogEng, getNews, getNewsFr, getNewsNl, getSinglePostEng, getSinglePostFr, getSinglePostNl, getRecordings,  getGallery}