import React, { useState, useEffect } from "react";
import {
  getProject,
  getProjectFr,
  getProjectNl,
  getProjectEng,
} from "../contentful";
import marked from "marked";
import { Row, Col } from "react-bootstrap";
import TitleBar from "./TitleBar";
import Translator from "./i18n/Translator";
import { Link, useParams } from "react-router-dom";

const Projects = () => {
  const [project, setProject] = useState([]);
  const { id } = useParams();

  let switchedLang = window.localStorage.getItem("lang");

  useEffect(() => {
    getProject(id).then((data) => setProject(data));
  },[id]);

  return (
    <div className="content">
      {project.map((project) => {

         let shortDesc;
        if(project.fields.shortDescription && switchedLang === 'en-US'){ shortDesc = marked(project.fields.shortDescription) }
        else if(project.fields.shortDescription && switchedLang === 'fr-FR'){ shortDesc = marked(project.fields.shortDescriptionFr) }
        else if(project.fields.shortDescription && switchedLang === 'nl-NL'){ shortDesc = marked(project.fields.shortDescriptionNl) }
  
        else if(project.fields.shortDescription && !switchedLang){ shortDesc = marked(project.fields.shortDescriptionNl) }
       
    

         
         let mainText;
       if( project.fields.mainText && switchedLang === 'en-US'){  mainText = marked(project.fields.mainText)}
       else if (project.fields.mainText && switchedLang === 'fr-FR'){ mainText = marked(project.fields.mainTextFr) }
       else if (project.fields.mainText && switchedLang === 'nl-NL'){ mainText = marked(project.fields.mainTextNl) }
      
       else if (project.fields.mainText && !switchedLang){ mainText = marked(project.fields.mainTextNl) }

        return (
          <div className="text-box">
            <TitleBar title={<Translator path={project.fields.title} />} />
            <Row>
              <Col>
                
                  <h5
                  style={{ textAlign: "right" }}
                  dangerouslySetInnerHTML={{ __html: shortDesc }}
                ></h5>
              </Col>
              <Col>
              {project.fields.mainImage &&  <img
                  style={{ width: "100%" }}
                  src={project.fields.mainImage.fields.file.url}
                  alt=""
                />}
              </Col>
            </Row>
            <div style={{padding: '20px', textAlign: 'justify'}} dangerouslySetInnerHTML={{__html: mainText}}></div>
<div className="text-center">
{project.fields.link && <a className="xsmall-button" href={project.fields.link}>to the project website</a>}
</div>
       <Row style={{marginTop: '25px'}}>
            {project.fields.imageGallery && project.fields.imageGallery.map(image =>
            <Col md={4}><img style={{width:'100%', marginBottom:'20px'}} src={image.fields.file.url} alt=""/></Col>
            )}
                        </Row>

          </div>
        );
      })}
    </div>
  );
};

export default Projects;
