import React from 'react'
import BlogItems from './BlogItems'
import { getNews, getNewsFr, getNewsNl } from '../contentful'
import TitleBar from './TitleBar'
import Translator from './i18n/Translator'

class Posts extends React.Component {
  state = {
    posts: []
  }


  componentDidMount() {
    window.scrollTo(0, 0);

    var switchedLang = window.localStorage.getItem('lang')

        if(switchedLang === 'en-US'){ getNews().then(data => this.setState({posts: data}))}
        if(switchedLang === 'fr-FR'){ getNewsFr().then(data => this.setState({posts: data}))}
        if(switchedLang === 'nl-NL'){ getNewsNl().then(data => this.setState({posts: data}))}

       
  }
  
   
 
  render() {
    return (
      
        <div className='content'>
        <TitleBar title={<Translator path="News" />} />
      <BlogItems posts={this.state.posts}/>
      <br/>
     
    </div>
    )
  }
}
export default Posts