import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Translator from "./i18n/Translator";
import { getPrivacy } from "../contentful";
import marked from "marked";
import { FaTimesCircle } from "react-icons/fa";

Modal.setAppElement('#root');


const Privacy = (props) => {
  const [text, setText] = useState([]);

  useEffect(() => {
    getPrivacy().then(data => setText(data)
    )
  },[])

  let switchedLang = window.localStorage.getItem("lang");


  return (
    <div>
    <Modal
      isOpen={props.openModal}
      style={{ overlay: { backgroundColor: "rgba(0,0,0,.5)" } }}
      closeTimeoutMS={200}
    >
      <div>
        <p>Privacy text goes here</p>
        {text && text.map((text) => {
          let content;
          if(switchedLang === 'en-US'){ content = marked(text.fields.contentEng);}
          else if(switchedLang === 'fr-FR'){ content = marked(text.fields.contentFr);}
          else if(switchedLang === 'nl-NL'){ content = marked(text.fields.contentNl);}
          else if(!switchedLang){ content = marked(text.fields.contentNl);}

          return (
            <div className="content" style={{color:'black'}}>
              <FaTimesCircle
                className="close-cross"
                size={25}
                onClick={props.closeModal}
              />
              <div className="text-center">
                <div 
                  className="privacy-text"
                  dangerouslySetInnerHTML={{ __html: content }}
                ></div>

                <button className="xsmall-button" onClick={props.closeModal}>
                  <Translator path="close" />
                </button>
              </div>
            </div>
          );
        })} 
      </div>
    </Modal>
    </div>
  );
};

export default Privacy;
