import React, { useState, useEffect } from 'react'
import { getRecordings } from '../contentful'
import Translator from '../components/i18n/Translator'
import Recording from './Recording'
import TitleBar from './TitleBar'

const Discography  = () => {
  const [recordings, setRecordings] = useState([])

  useEffect(() => {
    getRecordings().then(data => setRecordings(data))
  }, [])

        return (
            <div className="content">
                    <TitleBar title={<Translator path="Recordings"/>} />
            {recordings.map((cd, index) => <Recording cd={cd} key={index} index={index} />)}
            
        
            
            </div>
        )
    }


export default Discography