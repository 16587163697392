export default {
    translations: {
        
        About: 'Biog',
        Projects: 'Projecten',
        Recordings: 'Opnames',
        Media: 'Media',
        Calendar: 'Calendar',
        Archive: 'Archive',

        Contact: 'Contact',
        ContactText: 'ik hoor graag van u',
        News: 'Nieuws',
        RecordingInfo: 'Vioolconcerti H.-J. De Croes. Binnenkort!',
        FormText: 'Schrijf in op onze nieuwsbrief',
        FormName: 'Naam en voornaam ',
        FormEmail: 'Email ',
        PrivacyAgreed1: 'Ik ga akoord met de ',
        PrivacyAgreed2: 'privacy policy (GDPR)',
        FormButton: 'Inschrijven',
        Close: 'sluiten',
        Violin: 'viool',
        ToAllRecordings: 'naar alle opnames',
        ToAllVideos: 'naar alle videos'
                
                }
          
    
}