import React, { useState } from "react";
import Translator from './i18n/Translator'
import TitleBar from './TitleBar'
import ImageGrid from './imageGrid/ImageGrid'
import Modal from './imageGrid/Modal'
import { Row, Col } from 'react-bootstrap'
 


const Gallery = () => {

const [selectedImg, setSelectedImg] = useState(null)
const [title, setTitle] = useState(null)


  
    return (
        <div className="content">
        <TitleBar title={<Translator path="Gallery"/>} />
        <ImageGrid setSelectedImg={setSelectedImg} title={title} setTitle={setTitle}/>
       {selectedImg && <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg} title={title} />}

<hr/>
<div>
    <h3>Downloads</h3>

</div>
</div>
    );
  
}

export default Gallery;
