export default {
  translations: {
      

    About: 'Biog',
    Projects: 'Projets',
    Recordings: 'Engregistrements',
    Media: 'Média',
    Calendar: 'Calendar',
    Archive: 'Archive',

    Contact: 'Contact',
    ContactText: 'Nous aimerions avor de vos nouvelles',
    News: 'Actualités',
    RecordingInfo: 'Concertos pour violon H.-J. De Croes. Bientôt!',
  
    FormText: 'Abonnez-vous à notre newsletter',
    FormName: 'Nom et prénom',
    FormEmail: 'Email',
    PrivacyAgreed1: "Je suis d'accord avec la",
    PrivacyAgreed2: ' politique ser la protection de la vie privée (RGDP)',
    FormButton: "s'inscrire",
    Privacy: 'Click here to read our privacy policy',
    Close:'Fermer',
    Violin: 'violon',
    ToAllRecordings: 'to all recordings',
    ToAllVideos: 'to all videos'
  
  }
        
  
}