import React, { useState, useEffect } from 'react'
import Translator from '../i18n/Translator'
import { getVideos } from '../../contentful'
import { Row, Col } from 'react-bootstrap'
import TitleBar from '../TitleBar'
import marked from 'marked';

const Videos = () => {

    const [videos, setVideos] = useState([])
    
useEffect(() => {
    getVideos().then(data => setVideos(data))
}, [])

   
        return (
        <div className="content text-center">
        <TitleBar title={<Translator path="Video"/>} />

        <Row style={{marginBottom:'20px'}}>   
        {videos.map((video, index) =>{
        const videoCode = marked(video.fields.youTubeCode)
        return <Col md={6} key={index} > <div className="video-container" dangerouslySetInnerHTML={{__html: videoCode}} />  </Col>})}
        </Row>
        <a  className="xsmall-button" href="https://www.youtube.com/results?search_query=ann+cnop" target="_blank">more</a>

        </div>

        )
    }


export default Videos
