import React from "react";
import marked from "marked";
import { Link } from "react-router-dom";
import Translator from './i18n/Translator'
import { FaTwitter, FaFacebook } from "react-icons/fa";

import { Row, Col } from "react-bootstrap";

const BlogItem = ({ article }) => {
  console.log(article);
  const { title, mainImage, content, label, videoMp4 } = article.fields;
  const postBody = marked(content);
  const excerpt = postBody.split(" ").splice(0, 20).join(" ") + "...";

  return (
    <div className="news-item">
      <Row>
            {mainImage &&   <Col> <img style={{width:'100%'}} src={mainImage.fields.file.url} /></Col>}

        <Col>      <h5>{title}</h5>
       { content && <section dangerouslySetInnerHTML={{ __html: postBody }} />}

</Col>
      </Row>

      {videoMp4 && 
      <video className="video" controls>
      <source
        src={videoMp4.fields.file.url}
        type="video/mp4"
      />
      Your browser does not support the video tag
    </video>
      
      }


     

      <hr />
    </div>
  );
};

export default BlogItem;
