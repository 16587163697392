import React, { useState, useEffect } from "react";
import Translator from "./i18n/Translator";
import { Row, Col } from "react-bootstrap";
import { getBiogEng } from "../contentful";
import TitleBar from "./TitleBar";
import marked from "marked";

import portrait from "../assets/imgs/Ann1.jpg";

const Biog = () => {
  const [text, setText] = useState([]);
  let switchedLang = 'nl-NL'
  if(window.localStorage.lang) {switchedLang = window.localStorage.getItem("lang");}

  useEffect(() => {
   getBiogEng().then((data) => setText(data))}, []);

  return (
    <div className="content">
      <TitleBar title={<Translator path="About" />} />
      <Row>
        <Col md={6}>
          {text.map(image => <img style={{ width: "100%" }} src={image.fields.image.fields.file.url} alt="" />)}
 
        </Col>
         <Col>
          {text.map(content => {
            let biog1; 
            let biog2;
            if(switchedLang === 'en-US'){
             biog1 = marked(content.fields.paragraph1);
             biog2 = marked(content.fields.paragraph2);
          }
              else if(switchedLang === 'fr-FR'){
               biog1 = marked(content.fields.paragraph1Fr);
               biog2 = marked(content.fields.paragraph2Fr);
              }

              else if(switchedLang === 'nl-NL'){
               biog1 = marked(content.fields.paragraph1Nl);
               biog2 = marked(content.fields.paragraph2Nl);
              }

              


            return (
              <div className="text-box">
                <section
                 
                  dangerouslySetInnerHTML={{ __html: biog1 }}
                ></section>
                <section
                  dangerouslySetInnerHTML={{ __html: biog2 }}
                />
              </div>
            );
          })}
        </Col> 
      </Row>
      <hr />
      <Row>
        <Col md={6}>
        
        </Col>
      </Row>
    </div>
  );
};

export default Biog;
