import React, { Component } from "react";
import "../styles/stylesheet.css";
import { Row, Col } from "react-bootstrap";
import { getHomepageText, getProjects, getVideos, getRecordings, getCalendarEng } from "../contentful";
import { Link } from "react-router-dom";
import marked from "marked";

import Translator from "./i18n/Translator";

import portrait from "../assets/imgs/Ann4.jpg";
import portrait2 from "../assets/imgs/Ann2.jpg";

class Main extends Component {
  state = {
    news: [],
    projects: [],
    calendar: [],
    videos: [],
    homeText: [],
    recordings: []
  };

  getMarkdownText() {
    var rawMarkup = marked(this.state.video[0].fields.youTube, {
      sanitize: true,
    });
    return { __html: rawMarkup };
  }

  componentDidMount() {
    getCalendarEng().then(data => this.setState({calendar: data}))
    getProjects().then((data) => this.setState({ projects: data }));
    getVideos().then((data) => this.setState({ videos: data }));
    getHomepageText().then(data => this.setState({homeText: data}))
    getRecordings().then(data => this.setState({recordings: data}))
  }


  render() {
    return (
      <div>
        <div style={{marginBottom:'25px'}}  className="hero">
          <div className="hero-text">
            <h1>Ann Cnop</h1>
            <h3 id="hero-subtitle">
              <Translator path="Violin" />
            </h3>
          </div>
        </div>
        <div className="middle">
          <Row>
            <Col md={6}>
              <div className="middle-text">
                {this.state.homeText.map(text => {
                    let switchedLang = window.localStorage.getItem("lang");
                  let content;                 
                  if(!switchedLang ){content = marked(text.fields.contentNl)}

                  else if(switchedLang === 'en-US'){content = marked(text.fields.contentEng)}
                  else if(switchedLang === 'fr-FR'){content = marked(text.fields.contentFr)}
                  else if(switchedLang === 'nl-NL'){content = marked(text.fields.contentNl)}

                  return <div dangerouslySetInnerHTML={{__html: content}} />
                })}
              </div>

              <div className="middle-text">
              <div className="text-box">
                <h2><Translator path="Calendar"/></h2>
                {this.state.calendar.map((project) => {
                let switchedLang = window.localStorage.getItem("lang");
                let content;
               
                if(project.fields.shortDescriptionNl && !switchedLang ){content = marked(project.fields.shortDescriptionNl)}
                else if(project.fields.shortDescription && switchedLang === 'en-US'){content = marked(project.fields.shortDescription)}
                else if(project.fields.shortDescriptionFr && switchedLang === 'fr-FR'){content = marked(project.fields.shortDescriptionFr)}
                else if(project.fields.shortDescriptionNl && switchedLang === 'nl-NL'){content = marked(project.fields.shortDescriptionNl)}

                let date = new Date(project.fields.date)

                  return <div className="home-project-list">
                    <Row>
                      <Col> <Link to="/calendar">
                      <h5 style={{ fontWeight: "200", color: "white" }}>
                       {date.toLocaleDateString()}:  {project.fields.title}
                      </h5>
                <p><b>{project.fields.venue}</b></p>
                <p style={{fontWeight: '200'}}>{project.fields.description}</p>
                    </Link>
                    </Col>
                      <Col className="text-right">                    
                      { project.fields.image && <img style={{width:'100%'}} src={project.fields.image.fields.file.url}/>}
                      </Col>
                   
                    </Row>
                  </div>
                }
                )}
              </div>
              </div>
            </Col>
            <Col md={6}>
              <img
                style={{ width: "100%", padding: "20px" }}
                src={portrait}
                alt=""
              />
            </Col>
          </Row>
        </div>

        <div className="lower-middle" />

        <Row>
          <Col>
            <div className="middle-text">
              <img
                style={{ width: "100%", padding: "20px" }}
                src={portrait2}
                alt=""
              />
            </div>
          </Col>
          <Col>
            <div className="middle-text">
              <div className="text-box">
                <h2><Translator path="Recordings" /></h2>
                <Row>
                {this.state.recordings.slice(0,6).map(cd => 
                <Col md={4}>
                 
                  <img style={{width:'100%'}} src={cd.fields.image.fields.file.url} alt=""/>
                 
                  </Col>
                )}
                </Row>
                   <Link to="/recordings">
                  <h4 style={{ marginTop:'20px', color: "white", fontWeight: "200" }}>
                    <Translator path="ToAllRecordings" />
                  </h4>
                </Link>
              </div>
              <hr />

              <div>
                {this.state.videos.slice(0, 1).map((data) => {
                  const code = marked(data.fields.youTubeCode);
                  return (
                    <div
                      style={{ width: "100%" }}
                      dangerouslySetInnerHTML={{ __html: code }}
                    />
                  );
                })}

                <Link to="/videos">
                  <h4 style={{ color: "white", fontWeight: "200" }}>
                    <Translator path="ToAllVideos" />
                  </h4>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Main;
