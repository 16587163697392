import React, { useEffect, useState } from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaEnvelopeOpen,
  FaPhoneAlt,
} from "react-icons/fa";
import Translator from "./i18n/Translator";
import TitleBar from "./TitleBar";
import Privacy from './Privacy';
import { Row, Col } from 'react-bootstrap'
import {getContactPage} from '../contentful'

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getContactPage().then(data => setContactPage(data)
    )
  }, []);

  const [modalOpen, setModalOpen] = useState(false)
  const [contactPage, setContactPage] = useState([])




  return (
    <>
      <div className="content">
        <TitleBar title={<Translator path="Contact" />} />
        <Row>
    <Col>
    
    <div className="contact-middle text-center">
          <a href="mailto:cnopann@gmail.com">
            <div style={{ marginBottom: "15px" }}>
              <FaEnvelopeOpen size={44} />
              <br></br>
            </div>

            <Translator path="ContactText" />
            <p>cnopann@gmail.com</p>
          </a>
          <div style={{ paddingBottom: "15px" }}>
            <FaPhoneAlt /> +32 477 53 97 48
          </div>

    
         
            <form className="form" action="https://formspree.io/f/xdopdajy" method="POST">
         
            <p><b><Translator path="FormText" /> </b> </p>
              <label>
                <Translator path="FormName" />
                <input className="form-control" type="text" required name="name"/>
                <Translator path="FormEmail" />
                <input className="form-control" type="email" name="_replyto" required />
              </label>
             <label>
               <span><Translator path="PrivacyAgreed1" /></span><span className="privacy-link" onClick={() => setModalOpen(true)}><Translator path="PrivacyAgreed2" /></span>
               <input className="privacy-button" type="checkbox" name="privacy agreed" required/>
             </label>

              <button style={{width:'50%', alignSelf:'center'}} className="xsmall-button" type="submit">
                <Translator path="FormButton" />
              </button>
            </form>
          
<Privacy openModal={modalOpen} closeModal={() => setModalOpen(false)} />
<div className="contact-social contact-middle">
<a
              href="https://www.facebook.com/ann.cnop.5"
              target="_blank"
            >
              <FaFacebook  size={40} />
            </a>
            <a
              href="https://www.linkedin.com/in/ann-cnop-501506105/"
              target="_blank"
            >
              <FaLinkedin  size={40} />
            </a>
            <a
              href="https://www.instagram.com/ann_cnop/"
              target="_blank"
            >
              <FaInstagram  size={40} />
            </a>
            </div>
</div>
<div style={{marginTop: '25px'}} className="text-box text-center">
<Translator path="Photos" /><a href="https://www.facebook.com/jophotography.be/">Jo Photography</a>
</div>
    
    </Col>

    <Col>
    {contactPage.map(image =><img src={image.fields.image.fields.file.url} style={{width:'100%'}} alt=""/> )}
    </Col>

</Row>











         
      
        {/* <div className="social">
            <a href="https://www.facebook.com/tabea.debus" target="_blank"><FaFacebook size={35}/></a>
        
            <a href="https://twitter.com/TabeaDebus" target="_blank"><FaTwitter size={35}/></a>
      
            <a href="https://www.youtube.com/channel/UCIwHWkZF447ssRHDaTpWJpg" target="_blank"><FaYoutube size={35}/></a>
      
            <a href="https://open.spotify.com/artist/1MbLfjWZWdE2dt3yOJnU4e?si=Pz6mmUhSTG2Nwwu8G5Xlrg" target="_blank"><FaSpotify size={35}/></a>
        </div> */}
      </div>
    </>
  );
};

export default Contact;
