import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import marked from 'marked'
import { Row, Col} from 'react-bootstrap';
import { getSingleRecording } from '../contentful';

const SingleRecording = (props) => {
const [recording, setRecording] = useState([])
const { id } = useParams();
    useEffect(() => {
        getSingleRecording(id).then(data => setRecording(data)
        
        )

    },[])

    return  recording.map(cd => {
let trackList
if(cd.fields.trackList){trackList = marked(cd.fields.trackList)}
let description
if(cd.fields.description) description = marked(cd.fields.description)
        return (
            <div className="content">
                <Row>

<Col md={4} >
<div className="text-box-light single-cd">
<div>
<img style={{width:'100%'}} src={cd.fields.image.fields.file.url} alt=""/>
</div>
<div className="single-cd-links">
        { !cd.fields.artistLink && <p><em>{cd.fields.artist}</em></p>}
{cd.fields.artistLink && <a href={cd.fields.artistLink}>{cd.fields.artist}</a>  } <br/>
<a className="label-name" href={cd.fields.labelLink} target="_blank">{cd.fields.labelName}</a>
</div>
<div style={{textAlign:'center', marginBottom:'20px'}}>
        {cd.fields.orderNowLink && <a className="xsmall-button" target="_blank" href={cd.fields.orderNowLink}>Order Now</a> }
        </div>
        {cd.fields.audio && cd.fields.audio.map(
            track => <div className="audio-file" >
         
            <small>{track.fields.title}</small>
           <audio style={{width: '100%'}} controls>
         <source
          src={track.fields.file.url}
           type=""
           />
            Your browser does not support the video tag
           </audio>
           </div> 
        )

        }
       
     
        </div>
</Col>
<Col md={8}>
<div className="text-box-light">
<section dangerouslySetInnerHTML={{__html:description}} />
        <section dangerouslySetInnerHTML={{__html:trackList}} />
        </div>
        </Col>
                </Row>
                
     
        </div>
        )
    })
       }

    



export default SingleRecording;